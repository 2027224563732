@import "../common_variables";

.mat-dialog-container {
  @include mat-elevation(16);
}

@mixin nxn-material-components-theme($theme) {
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  // make material spinner color consistent with nx-spinner color
  .mat-progress-spinner,
  .mat-spinner {
    circle,
    &.mat-accent circle {
      stroke: map_get($background, spinner-stroke-color) !important;
    }
  }

  .mat-select-panel {
    background-color: map_get($background, nxn-page-main-panel-bg-color);
    border: 1px solid map_get($foreground, dividers);
  }
}
