@import "../common_variables";
@import "../mixins/flexbox_layout";

@mixin nxn-list-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  .nxn-list-with-divider {
    list-style-type: none;
    padding-left: 0;
    margin: 0;

    // this is used within the whole page, and it is narrow on large breakpoints
    &.narrow-list {
      @media (min-width: 992px) {
        margin: 0 100px;
      }

      @media (min-width: 1200px) {
        margin: 0 200px;
      }
    }

    &.dense-list {
      li,
      li:first-child {
        padding: 8px 0;
      }
    }

    &.no-border-for-last-item li {
      &:last-child {
        border-bottom: none;
      }
    }

    &.various-width {
      @media (min-width: 1200px) {
        width: 50%;
      }
    }

    &.no-wrap li {
      white-space: nowrap;
    }

    li {
      padding: 15px 0;
      border-bottom: 1px solid map_get($foreground, dividers);

      a:not(.disabled)  {
        color: map_get($foreground, text);
      }

      &:first-child {
        padding-top: 0;
      }

      .date {
        color: map_get($foreground, secondary-text);
      }

      .uppercase {
        text-transform: uppercase;
      }

      .link {
        a {
          font-weight: bold;
          //color: map_get($foreground, text);
          font-size: 16px;
        }
      }

      .text {
        color: map_get($foreground, text);
      }
    }
  }
}
