@import "../common_variables";
@import "../mixins/flexbox_layout";

// Article Theming
@mixin nxn-article-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  .heading-label {
    display: block;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 0.8rem;
    color: map_get($foreground, hint-text);

    @media (min-width: 768px) {
      margin-top: 3rem;
    }
  }

  .article-city,
  .article-date {
    color: map_get($foreground, hint-text);
    margin-bottom: 14px;
  }

  .article-city {
    text-transform: uppercase;
  }
}
