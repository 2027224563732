@import '@angular/material/theming';
@import 'nx-components/theming';
@import 'polaris-palette';
@import '../../apps/common/theming/all-theme';

@include nx-core(nx-typography-config($assetPath: '../../../assets/', $styleHtmlTags: false));

// Light theme palettes
$polaris-accent: nx-palette($polaris-accent);
$polaris-primary: nx-palette($polaris-primary);
$polaris-warn: nx-palette($polaris-warn);

// Dark theme palettes
$polaris-accent-dark: nx-palette($polaris-accent-dark);
$polaris-primary-dark: nx-palette($polaris-primary-dark);
$polaris-warn-dark: nx-palette($polaris-warn-dark);

// Define Light theme
$polaris-light-theme: nx-light-theme(
    $polaris-primary,
    $polaris-accent,
    $polaris-warn,
    $polaris-light-theme-foreground,
    $polaris-light-theme-background);

// Define Dark theme
$polaris-dark-theme: nx-dark-theme(
    $polaris-primary-dark,
    $polaris-accent-dark,
    $polaris-warn-dark,
    $polaris-dark-theme-foreground,
    $polaris-dark-theme-background);

// Build Light Theme
@include nx-components-theme($polaris-light-theme);
@include nxn-components-theme($polaris-light-theme); // components from this repo

// Build Dark Theme
// Include the alternative theme styles inside of a block with a CSS class. You can make this
// CSS class whatever you want. In this example, any component inside of an element with
// `.dark-theme` will be affected by this alternate dark theme instead of the default theme.
.dark-theme {
  @include nx-components-theme($polaris-dark-theme); // nx-components
  @include nxn-components-theme($polaris-dark-theme); // components from this repo
}

// Polaris Customization
.portal-login-features-container,
.dark-theme .portal-login-features-container {
  background: none;
  box-shadow: none;

  @media screen and (max-width: 767px) {
    display: block;
  }
}
