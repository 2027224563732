@import "../common_variables";
@import "../mixins/flexbox_layout";

@mixin nxn-footer-logo-theme($theme) {
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $nx-footer-image: map-get($background, nxn-footer-logo-img);

  .nxn-footer-logo {
    background-image: url($nx-footer-image);
    background-position: map_get($background, nxn-footer-logo-bg-position);
    background-repeat: no-repeat;
    width: auto;
    height: map_get($background, nxn-footer-logo-height);
  }
}

// test with CSS variables for custom components
@mixin footer-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  body {
    --footerCopyrightColor: #{map_get($foreground, text)};
    --footerBackground: #{map_get($background, background)};
    --footerNavLinkColor: #{mat-color($primary, 500)};
    --footerNavLinkColorHover: #{mat-color($warn, 500)};
  }

  // this code will be within the component
  // we simply reset the CSS variables in this mixin
  .nexen-footer {
    background-color: var(--footerBackground) !important;

    .footernav li a {
      color: var(--footerNavLinkColor) !important;

      &:hover {
        color: var(--footerNavLinkColorHover) !important;
      }
    }

    .nxn-copyright {
      color: var(--footerCopyrightColor) !important;
    }
  }
}
